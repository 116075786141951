$(document).ready(function() {
  //--------------------IMAGE LOADING--------------------
  $('.bgFadeImage').each(function() {
    var $bg = $(this);
    var image_url = $bg.data('src');
    var $img = new Image();
    $img.onload = function() {
      $bg.css({
        'background-image': 'url(' + image_url + ')'
      });
      $bg.addClass('loaded');
    }
    $img.src = image_url;

  });

  // IMAGES
  $('img.imgSrcLoad').each(function(){
    var src = $(this).data('src');
    if(src.indexOf('/thumbs') == 0){
      var srcA = src.split('/');
      var srcB = srcA[2].split('x');
      var sw = srcB[0];
      var sh = srcB[1];
      var tw = $(this).width();
      var tempH = Math.floor(tw * (sh/sw));
      $(this).css('height',tempH + 'px');
    }
  });
  /*
  Set to "C" to zoom-crop towards the center,
  or set to "T", "B", "L", "R", "TL", "TR", "BL", "BR"
  */
  $('img.imgSrc50').each(function(){
    var src = $(this).data('src');
    if(src.indexOf('/thumbs') == 0){
      var srcA = src.split('/');
      var srcB = srcA[2].split('x');
      $(this).attr('data-w',srcB[0]);
      $(this).attr('data-h',srcB[1]);
      $(this).attr('data-w2',Math.floor(srcB[0]/2));
      $(this).attr('data-h2',Math.floor(srcB[1]/2));
      $(this).data('which',0);
      if($(this).data('crop')){
        var rep = 'x50x' + $(this).data('crop') + '/files';
        var src50 = src.replace('/files',rep);
      }else{
        var src50 = src.replace('/files','x50/files');
      }
      $(this).attr('data-src100',src);
      $(this).attr('data-src50',src50);
      $(this).on('load', function () {
        $(this).addClass('imgSrcLoadOn');
      });
    }
  });
  $('.bgFadeImage').each(function(){
    var src = $(this).data('src');
    if(src.indexOf('/thumbs') == 0){
      var srcA = src.split('/');
      var srcB = srcA[2].split('x');
      $(this).attr('data-w',srcB[0]);
      $(this).attr('data-h',srcB[1]);
      $(this).attr('data-w2',Math.floor(srcB[0]/2));
      $(this).attr('data-h2',Math.floor(srcB[1]/2));
      $(this).data('which',0);
      if($(this).data('crop')){
        var rep = 'x50x' + $(this).data('crop') + '/files';
        var src50 = src.replace('/files',rep);
      }else{
        var src50 = src.replace('/files','x50/files');
      }
      $(this).attr('data-src100',src);
      $(this).attr('data-src50',src50);
      $(this).on('load', function () {
        $(this).addClass('imgSrcLoadOn');
      });
    }
  });
});
function imgSrc50Go(){
  $('img.imgSrc50').each(function(){
    if($(this).data('crop')){
      var dw = 550;
    }else{
      var dw = $(this).data('w2');
    }
    if($(window).width() < dw && $(this).data('which') != 50){
      $(this).attr('src',$(this).data('src50'));
      $(this).data('which',50);
    }else if($(window).width() > dw && $(this).data('which') != 100){
      $(this).attr('src',$(this).data('src'));
      $(this).data('which',100);
    }
  });
  $('.bgFadeImage').each(function(){
    if($(this).data('crop')){
      var dw = 550;
    }else{
      var dw = $(this).data('w2');
    }
    if($(window).width() < dw && $(this).data('which') != 50){
      $(this).attr('src',$(this).data('src50'));
      $(this).data('which',50);
    }else if($(window).width() > dw && $(this).data('which') != 100){
      $(this).attr('src',$(this).data('src'));
      $(this).data('which',100);
    }
  });
}
$(window).on('load', function() {
  $('img.imgSrcLoad').each(function(){
    var src = $(this).data('src');
    $(this).attr('src',src);
    $(this).on('load', function () {
      $(this).addClass('imgSrcLoadOn');
    });
    $(this).on('load', function(){
      imgSrc50Go();
    });
  });
});
$(document).ready(function(){
  imgSrc50Go();
});
$(window).on('load', function() {
  imgSrc50Go();
});
$(window).resize(function(){
  imgSrc50Go();
});
