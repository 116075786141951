"use strict";

$(document).ready(function() {
  /* ---------------------------------------------
  HAMBURGER MENU
  ------------------------------------------------ */
  $('.hamburger').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('open-mobile-nav');
  });

  /* ---------------------------------------------
    SWIPER
  ------------------------------------------------ */
  var swiper = new Swiper('.swiper-container', {
    speed: 400,
    allowTouchMove: true,
    preventClicks: false,
    preventClicksPropagation: false,
    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
  });

  var modalSwiper = new Swiper('.swiper-container-modal', {
    speed: 400,
    allowTouchMove: true,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },

    loop: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: true,
    },
  });


    /* ---------------------------------------------
      TESTIMONIAL SLIDER
    ------------------------------------------------ */

    var quotesCount = $('.quote-item').length;
    var isMobile = $(window).innerWidth() <= 768;

    var currentQuoteIndex = isMobile ? 0 : 1;

    var quotesTimer = setInterval(function () {
      nextSlides();
    }, 14000);

    $('.next-quotes').on('click', function (e) {
      e.preventDefault();
      // Switch slides
      nextSlides();
      // Update the timer
      clearInterval(quotesTimer);
    });

    function nextSlides () {
      isMobile = $(window).innerWidth() <= 768;

      // Update The Counter
      if (isMobile) {
        currentQuoteIndex = (currentQuoteIndex < quotesCount - 1) ? currentQuoteIndex + 1 : 0;
      } else {
        currentQuoteIndex = (currentQuoteIndex < quotesCount - 1) ? currentQuoteIndex + 2 : 1;
      }

      var slide1 = currentQuoteIndex - 1;


      // Update UI
      $('.quote-item').hide();
      // Fade in two slides if the screen is tablet width or wider
      if (!isMobile) {
        $('.quote-item').eq(currentQuoteIndex - 1).fadeIn(400);
      }

      $('.quote-item').eq(currentQuoteIndex).fadeIn(400);


    }

    /* ---------------------------------------------
    Buy Now Scroll
    ------------------------------------------------ */
    $("#buy-now").click(function(e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $("#vendors").offset().top
      }, 400);
    });

    /* ---------------------------------------------
    Read More
    ------------------------------------------------ */
    $('#read-more').on('click', function (e) {
      e.preventDefault();
      $(this).hide();
      $('.read-more-text').fadeIn();

    });

});
